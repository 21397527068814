import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  Stack,
  Box,
  useMediaQuery,
  useTheme,
  Divider,
  ListItemButton,
  Typography,
  Button,
  Container,
  IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField,Link,Tooltip
} from "@mui/material";
import {
  AccountCircle as AccountCircleIcon,
  LocalAtm as LocalAtmIcon,
  PointOfSale as PointOfSaleIcon,
  Restore as RestoreIcon,
  ListAlt as ListAltIcon,
  Refresh,
  Discount,
  LockOpen, Forward,
} from "@mui/icons-material";
import { setAlert } from "../features/slices/alertSlice";
import UserProfile from "../components/wallet/UserProfile";
import Deposit from "../components/wallet/Deposit";
import Withdrawal from "../components/wallet/Withdrawal";
import Transaction from "../components/wallet/Transaction";
import Backdrop from "../components/common/Backdrop";
import BetRecord from "../components/wallet/BetRecord";
import BankAccount from "../components/wallet/BankAccount";

import {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useGetShareLinkQuery,
  usePlayerBanksMutation,
  useGetBankAccountsQuery,
  useLazyPlayerGetOtpQuery,
  useLazyPlayerVerifyOtpQuery,
  useVerifyIdentityMutation
} from "../features/api/userApiSlice";
import {
  useGetDepositDetailsQuery,
  useDepositMutation,
  useGetWithdrawDetailsQuery,
  useWithdrawMutation,
  useGetHistoryMutation,
} from "../features/api/transactionApiSlice";
import { apiSlice } from "../features/api/apiSlice";
import i18n, { t } from "i18next";
import { useGetPromotionRecordMutation } from "../features/api/bonusApiSlice";
import { useListBetHistoryMutation } from "../features/api/gameApiSlice";
import PromotionRecord from "../components/wallet/PromotionRecord";
import ChangePassword from "../components/wallet/ChangePassword";
import WalletCard from "../components/common/WalletCard";
import Image from "../components/common/Image";
import * as Constant from "../features/constant";
import IdVerification from "../components/wallet/IdVerification";
import SecurityCenter from "../components/wallet/SecurityCenter";
import InviteFriends from "../components/wallet/InviteFriends";

const PATH = process.env.PUBLIC_URL;

const ProfileWallet = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletView = useMediaQuery(theme.breakpoints.down("lg"));
  const [selectedScreen, setSelectedScreen] = useState(
    location?.state?.screen || "My Account"
  );
  const [seconds, setSeconds] = useState(null);
  const searchParams = new URLSearchParams(location.search);
  const paramTab = searchParams.get("tab");

  const handleIDVerificationOpen = () => {
    setIDVerificationOpen(true);
  };

  const handleIDVerificationClose = () => {
    setIDVerificationOpen(false);
  };

  const handleEmailOpen = () => {
    setEmailOpen(true);
    getOtp("EMAIL");
    setSeconds(120);
  };

  const handleEmailClose = () => {
    setEmailOpen(false);
    setEmailCode(['', '', '', '', '', '']);
  };

  const handlePhoneOpen = () => {
    setPhoneOpen(true);
    getOtp("MOBILE");
    setSeconds(120);
  };

  const handlePhoneClose = () => {
    setPhoneOpen(false);
    setPhoneCode(['', '', '', '', '', '']);
  };

  const [emailOpen, setEmailOpen] = useState(false);
  const [phoneOpen, setPhoneOpen] = useState(false);
  const [emailCode, setEmailCode] = useState(['', '', '', '', '', '']);
  const [phoneCode, setPhoneCode] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);
  const [IDVerificationOpen, setIDVerificationOpen] = useState(false);

  const focusNextInput = (index) => {
    if (inputRefs.current[index + 1]) {
      inputRefs.current[index + 1].focus();
    }
  };

  const focusPreviousInput = (index) => {
    if (inputRefs.current[index - 1]) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerifyEmail = async() => {
    // Perform email verification logic here
    let result = await verifyOtp({channel: "EMAIL", otp: emailCode.join("")});
    if (result.isSuccess) {
      handleEmailClose();
      dispatch(
        setAlert({
          isOpen: true,
          message: t("myaccountpage.verifyotpsuccess"),
          variant: "success",
        })
      );
      window.location.reload();
    }
    else {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("myaccountpage.verifyotpfailed"),
          variant: "error",
        })
      );
    }
  };

  const handleVerifyPhone = async() => {
    // Perform phone verification logic here
    let result = await verifyOtp({channel: "MOBILE", otp: phoneCode.join("")});
    if (result.isSuccess) {
      handlePhoneClose();
      dispatch(
        setAlert({
          isOpen: true,
          message: t("myaccountpage.verifyotpsuccess"),
          variant: "success",
        })
      );
      window.location.reload();
    }
    else {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("myaccountpage.verifyotpfailed"),
          variant: "error",
        })
      );
    }
  };

  const handleChangeEmail = (index, value) => {
    if (value.length > 1) {
      value = value.charAt(0);
    }
    const newEmailCode = [...emailCode];
    newEmailCode[index] = value;
    setEmailCode(newEmailCode);

    if (value !== '') {
      focusNextInput(index);
    } else {
      focusPreviousInput(index);
    }
  };

  const handleChangePhone = (index, value) => {
    if (value.length > 1) {
      value = value.charAt(0);
    }
    const newPhoneCode = [...phoneCode];
    newPhoneCode[index] = value;
    setPhoneCode(newPhoneCode);

    if (value !== '') {
      focusNextInput(index);
    } else {
      focusPreviousInput(index);
    }
  };


  const handleKeyDownPhone = (index, event) => {
    if (event.key === 'Backspace' && phoneCode[index] === '') {
      event.preventDefault();
      focusPreviousInput(index);
    }
  };
  const handleKeyDownEmail= (index, event) => {
    if (event.key === 'Backspace' && emailCode[index] === '') {
      event.preventDefault();
      focusPreviousInput(index);
    }
  };


  useEffect(() => {
    if (paramTab) {
      navigate(Constant.getPath("/profileWallet"), {
        state: {
          screen: paramTab,
        },
      });
    }
  }, [paramTab]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(prevSeconds => prevSeconds - 1);
      }
    }, 1000);

    // Cleanup function to clear the interval when component unmounts or when seconds reach 0
    return () => clearInterval(timer);
  }, [seconds]);

  const screens = [
    {
      label: i18n.t("walletcard.deposit"),
      code: "Deposit",
      icon: (
          <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF",}}>
        <Image
          width="30px"
          height="30px"
          src={PATH + `/assets/img/wallet/wallet_card/Deposit.png`}
        />
          </div>
      ),
      category: t("walletcard.banking"),
    },
    {
      label: i18n.t("walletcard.withdrawal"),
      code: "Withdrawal",
      icon: (
          <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF", // Border style and color
          }}>
          <Image
          width="30px"
          height="30px"
          src={PATH + `/assets/img/wallet/wallet_card/Withdraw.png`}
        />
          </div>
      ),
      category: t("walletcard.banking"),
    },
    {
      label: i18n.t("walletcard.transaction"),
      code: "Transaction",
      icon: (
          <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF",}}>
          <Image
          width="30px"
          height="30px"
          src={PATH + `/assets/img/wallet/wallet_card/Transaction.png`}
        />
          </div>
      ),
      category: t("walletcard.banking"),
    },
    {
      label: i18n.t("walletcard.betrecord"),
      code: "Bet Record",
      icon: (
          <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF",}}>
          <Image
          width="30px"
          height="30px"
          src={PATH + `/assets/img/wallet/wallet_card/Bet Record.png`}
        />
          </div>
      ),
      category: t("walletcard.banking"),
    },
    {
      label: i18n.t("walletcard.promotionrecord"),
      code: "Promotion Record",
      icon: (
          <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF",}}>
          <Image
          width="30px"
          height="30px"
          src={PATH + `/assets/img/wallet/wallet_card/Promotion.png`}
        />
          </div>
      ),
      category: t("walletcard.banking"),
    },
    {
      label: i18n.t("walletcard.myaccount"),
      code: "My Account",
      icon: (
          <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF",}}>
          <Image
          width="30px"
          height="30px"
          src={PATH + `/assets/img/wallet/wallet_card/My Account.png`}
        />
          </div>
      ),
      category: t("walletcard.account"),
    },
    {
      label: i18n.t("walletcard.bankAccount"),
      code: "Bank Account",
      icon: (
          <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF",}}>
            <Image
                width="30px"
                height="30px"
                src={PATH + `/assets/img/wallet/wallet_card/Bank Account.png`}
            />
          </div>
      ),
      category: t("walletcard.account"),
    },
    {
      label: i18n.t("walletcard.changepassword"),
      code: "Change Password",
      icon: (
          <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF",}}>
          <Image
          width="30px"
          height="30px"
          src={PATH + `/assets/img/wallet/wallet_card/Change Password.png`}
        />
          </div>
      ),
      category: t("walletcard.account"),
    },
    // {
    //   label: i18n.t("walletcard.IdVerification"),
    //   code: "ID Verification",
    //   icon: (
    //       <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF",}}>
    //         <Image
    //             width="30px"
    //             height="30px"
    //             src={PATH + `/assets/img/wallet/wallet_card/Bank Account.png`}
    //         />
    //       </div>
    //   ),
    //   category: t("walletcard.account"),
    // },
    //   {
    //   label: i18n.t("walletcard.securitycenter"),
    //   code: "Security Center",
    //   icon: (
    //       <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF",}}>
    //         <Image
    //             width="30px"
    //             height="30px"
    //             src={PATH + `/assets/img/wallet/wallet_card/Bank Account.png`}
    //         />
    //       </div>
    //   ),
    //   category: t("walletcard.account"),
    // },
    // {
    //   label: i18n.t("walletcard.invitefriends"),
    //   code: "Invite Friends",
    //   icon: (
    //       <div style={{ backgroundColor: "#000000", padding: "5px", borderRadius: "8px", border: "1px solid #FFFFFF",}}>
    //         <Image
    //             width="30px"
    //             height="30px"
    //             src={PATH + `/assets/img/wallet/wallet_card/Change Password.png`}
    //         />
    //       </div>
    //   ),
    //   category: t("walletcard.account"),
    // },

  ];

  const {
    data: user,
    isLoading: isUserLoading,
    isError: isUserError,
    isSuccess: isUserSuccess,
    error: userError,
  } = useGetProfileQuery();

  const {
    data: shareLink,
    isLoading: isShareLinkLoading,
    isError: isShareLinkError,
    isSuccess: isShareLinkSuccess,
    error: shareLinkError,
  } = useGetShareLinkQuery();

  const {
    data: depositDetails,
    isLoading: isDepositDetailsLoading,
    isError: isDepositDetailsError,
    isSuccess: isDepositDetailsSuccess,
    error: depositDetailsError,
  } = useGetDepositDetailsQuery();

  const {
    data: withdrawDetails,
    isLoading: isWithdrawDetailsLoading,
    isError: isWithdrawDetailsError,
    isSuccess: isWithdrawDetailsSuccess,
    error: withdrawDetailsError,
  } = useGetWithdrawDetailsQuery();
  
  const [
    getOtp,
    {
      data: getOtpData,
      // isFetching: isGetOtpFetching,
      isLoading: isGetOtpLoading,
      isError: isGetOtpError,
      isSuccess: isGetOtpSuccess,
      error: getOtpError,
    },
  ] = useLazyPlayerGetOtpQuery({ prefetch: true });

  const [
    verifyOtp,
    {
      data: verifyOtpData,
      // isFetching: isGetOtpFetching,
      isLoading: isVerifyOtpLoading,
      isError: isVerifyOtpError,
      isSuccess: isVerifyOtpSuccess,
      error: verifyOtpError,
    },
  ] = useLazyPlayerVerifyOtpQuery({ prefetch: true });

  useEffect(() => {
    setSelectedScreen((location?.state?.screen || "My Account"));
  }, [location]);

  if (
    isUserLoading ||
    isShareLinkLoading ||
    isDepositDetailsLoading ||
    isWithdrawDetailsLoading
  ) {
    return <Backdrop />;
  }

  return (
    <>
      <Box
        px={2}
        py={3}
        sx={{
          background: `url('${user?.webBackground[0]?.thumbnailUrl}')`,
          minHeight: mobileView && "100vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {!tabletView && (
          <Container>
            <Grid container direction="row" spacing={3}>
              <Grid item xs={3}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  flexDirection="column"
                  // px={3}
                  sx={{
                    bgcolor: theme.palette.background.paper,
                    height: "100%",
                  }}
                >
                  <Box my={3} px={3}>
                    <Grid sx={{ flexGrow: 1 }} container>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item display="flex" alignSelf="center">
                            <Box
                              display="flex"
                              justifyContent="center"
                              pl="15px"
                              pr="20px"
                            >
                              <AccountCircleIcon />
                            </Box>
                          </Grid>
                          <Grid item display="flex" alignSelf="center">
                            <Box display="flex">
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                <Typography variant="body2" color="white">
                                  {user?.result?.username}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="white"
                                >{`${process.env.REACT_APP_CURRENCY_CODE} ${user?.result?.currentCredit}`}</Typography>
                              </Box>
                              <IconButton
                                onClick={() =>
                                  dispatch(
                                    apiSlice.util.invalidateTags(["Profile"])
                                  )
                                }
                              >
                                <Refresh />
                              </IconButton>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* <Box>
                    <Box
                        sx={{
                          backgroundColor: '#FDB813',
                          padding: '5px',
                          borderRadius: '2px',
                          marginLeft: '5px',
                          marginRight: '5px',
                        }}>
                      <Typography mr={1} fontSize={12} color="white" style={{fontWeight: 'bold'}}>
                        {t("Verify Your Account NOW")}
                      </Typography>
                    </Box> */}
                    {/*<Box sx={{ display: 'flex', backgroundColor: 'grey', paddingTop: '5px',marginLeft:'5px', marginRight:'5px' }}>*/}
                    {/*  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>*/}
                    {/*    <Typography fontSize={12} sx={{color: 'white',marginLeft:"5px",fontWeight: 'bold' }}>*/}
                    {/*      {t("Verify for additional security")}*/}
                    {/*    </Typography>*/}
                    {/*    <Typography fontSize={12} sx={{color: 'white',marginLeft:"5px",fontWeight: 'bold',marginRight:'5px' }}>*/}
                    {/*      {t("25%")}*/}
                    {/*    </Typography>*/}
                    {/*  </Box>*/}
                    {/*</Box>*/}
                    {/* <Box sx={{display: "flex", backgroundColor: "grey", paddingTop: "5px",marginLeft:'5px', marginRight:'5px'}}>
                      <Grid container spacing={2} justifyContent="center"> */}
                      {/*<Grid item xs={3} sx={{paddingBottom: '5px',alignItems:'center'}}>*/}
                      {/*   <IconButton*/}
                      {/*        style={{width: '35px', height: '35px', marginLeft: '10px'}}>*/}
                      {/*       {user?.result?.isIdentityVerified ? (*/}
                      {/*           <img*/}
                      {/*               src={PATH + "/assets/img/wallet/verified_icon/id_verified.png"}*/}
                      {/*               style={{width: '35px', height: '35px'}}*/}
                      {/*           />*/}
                      {/*       ) : user?.result?.isIdVerificationSubmitted ? (*/}
                      {/*           <img*/}
                      {/*               src={PATH + "/assets/img/wallet/verified_icon/id_pre_verified.png"}*/}
                      {/*               style={{ width: '35px', height: '35px' }}*/}
                      {/*               onClick={handleIDVerificationOpen}*/}
                      {/*           />*/}
                      {/*       ) : (*/}
                      {/*           <img src={PATH + "/assets/img/wallet/verified_icon/id_pre_verified.png"} style={{*/}
                      {/*             width: '35px',*/}
                      {/*             height: '35px',*/}
                      {/*           }}*/}
                      {/*                onClick={() => {*/}
                      {/*                  navigate(Constant.getPath("/profileWallet"), {*/}
                      {/*                    replace: true,*/}
                      {/*                    state: { screen: "id verification" },*/}
                      {/*                  });*/}
                      {/*                }}*/}
                      {/*           /> )} /!* Adjust width and height as needed *!/*/}
                      {/*     <Dialog open={IDVerificationOpen} onClose={handleIDVerificationOpen} BackdropProps={{invisible: true}}>*/}
                      {/*       <DialogTitle sx={{padding: 0}}>*/}
                      {/*         <DialogActions>*/}
                      {/*           <Button onClick={handleIDVerificationClose}>X</Button>*/}
                      {/*         </DialogActions>*/}
                      {/*         <Typography*/}
                      {/*             sx={{*/}
                      {/*               textAlign: 'center',*/}
                      {/*               fontWeight: 'bold',*/}
                      {/*               fontSize: '20px',*/}
                      {/*               color: 'white',*/}
                      {/*             }}*/}
                      {/*         >*/}
                      {/*           In-Progress</Typography>*/}
                      {/*       </DialogTitle>*/}
                      {/*       <DialogContent>*/}
                      {/*         <Typography*/}
                      {/*             sx={{*/}
                      {/*               textAlign: 'center',*/}
                      {/*               fontSize: '12px',*/}
                      {/*               color: 'white'*/}
                      {/*             }}*/}
                      {/*         >*/}
                      {/*           You have submitted your ID Verification. Please wait for approval. </Typography>*/}
                      {/*         <br/>*/}
                      {/*         <Typography*/}
                      {/*             sx={{*/}
                      {/*               textAlign: 'center',*/}
                      {/*               fontSize: '12px',*/}
                      {/*               color: 'white'*/}
                      {/*             }}>*/}
                      {/*           Experiencing any issues?{' '}*/}
                      {/*           <Link href='/contactus' style={{color: 'red', textDecoration: 'none'}}>*/}
                      {/*             Contact Customer Support*/}
                      {/*           </Link>*/}
                      {/*         </Typography>*/}
                      {/*         <br/>*/}
                      {/*       </DialogContent>*/}
                      {/*     </Dialog>*/}
                      {/*    </IconButton>*/}
                      {/*  </Grid>*/}
                        {/*<Grid item xs={3} sx={{paddingBottom: '5px'}}>*/}
                        {/*  <IconButton*/}
                        {/*      style={{width: '35px', height: '35px', marginLeft: '10px'}}>*/}
                        {/*    {user?.result?.isBankVerified ? (*/}
                        {/*        <img*/}
                        {/*            src={PATH + "/assets/img/wallet/verified_icon/bank_verified.png"}*/}
                        {/*            style={{width: '35px', height: '35px'}}*/}
                        {/*        />*/}
                        {/*    ) : (*/}
                        {/*    <img src={PATH + "/assets/img/wallet/verified_icon/bank_pre_verified.png"} style={{*/}
                        {/*      width: '35px',*/}
                        {/*      height: '35px',*/}
                        {/*    }}*/}
                        {/*    onClick={() => {*/}
                        {/*      navigate(Constant.getPath("/profileWallet"), {*/}
                        {/*        replace: true,*/}
                        {/*        state: { screen: "bank account" },*/}
                        {/*      });*/}
                        {/*    }}/>)}*/}
                        {/*  </IconButton>*/}
                        {/*</Grid>*/}
                        {/* <Grid item xs={3} sx={{paddingBottom: '5px'}}>
                          <IconButton
                              style={{width: '35px', height: '35px', marginLeft: '10px'}}>
                            {user?.result?.isPhoneVerified ? (
                                <img
                                    src={PATH + "/assets/img/wallet/verified_icon/mobile_verified.png"}
                                    style={{width: '35px', height: '35px'}}
                                />
                            ) : (
                                <img
                                    src={PATH + "/assets/img/wallet/verified_icon/mobile_pre_verified.png"}
                                    style={{width: '35px', height: '35px'}}
                                    onClick={handlePhoneOpen}
                                />
                            )}
                            <Dialog open={phoneOpen} onClose={handlePhoneClose} BackdropProps={{invisible: true}}>
                              <DialogTitle sx={{padding: 0}}>
                                <DialogActions>
                                  <Button onClick={handlePhoneClose}>X</Button>
                                </DialogActions>
                                <Typography
                                    sx={{
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      fontSize: '20px',
                                      color: 'white',
                                    }}
                                >
                                  Verify Mobile Number</Typography>
                              </DialogTitle>
                              <DialogContent>
                                <Typography
                                    sx={{
                                      textAlign: 'center',
                                      fontSize: '12px',
                                      color: 'white'
                                    }}
                                >
                                  A 6-digit PIN number has been sent to your phone.</Typography>

                                <Typography
                                    sx={{
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      fontSize: '16px',
                                      color: 'white',
                                      paddingTop: '5px'
                                    }}
                                >
                                  ********{user?.result?.mobileNumber?.substring(user?.result?.mobileNumber?.length - 4, user?.result?.mobileNumber?.length - 1)}</Typography>
                                <br/>
                                <Grid container spacing={1} alignItems="center">
                                  {[...Array(6)].map((_, index) => (
                                      <Grid item key={index}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            inputRef={(el) => (inputRefs.current[index] = el)}
                                            value={phoneCode[index]}
                                            onChange={(e) => handleChangePhone(index, e.target.value)}
                                            inputProps={{
                                              maxLength: 1,
                                              style: {textAlign: 'center', width: '10px', height: '15px'}
                                            }}
                                        />
                                      </Grid>
                                  ))}
                                </Grid>
                                <br/>
                                <DialogActions sx={{justifyContent: 'center'}}>
                                  <Button variant="contained" color="error" onClick={handleVerifyPhone}>
                                    VERIFY
                                  </Button>
                                </DialogActions>
                                <br/>
                                <Typography
                                    sx={{
                                      textAlign: 'center',
                                      fontSize: '12px',
                                      color: 'white'
                                    }}>
                                  Experiencing any issues?{' '}
                                  <Link href='/contactus' style={{color: 'red', textDecoration: 'none'}}>
                                    Contact Customer Support
                                  </Link>
                                </Typography>
                                <br/>
                                <Typography
                                    sx={{
                                      textAlign: 'center',
                                      fontSize: '12px',
                                      color: 'white'
                                    }}
                                >
                                  Didn't receive the PIN number?{' '}
                                  <Typography
                                      component="span"
                                      sx={{ color: 'green', fontSize: '12px', textDecoration: 'none', cursor: seconds === 0 ? 'pointer' : 'not-allowed' }}
                                      onClick={seconds === 0 ? handlePhoneOpen : null}
                                  >
                                    Resend code ({seconds}s)
                                  </Typography>
                                </Typography>


                              </DialogContent>
                            </Dialog>
                          </IconButton>
                        </Grid>
                        <Grid item xs={3} sx={{paddingBottom: '5px'}}>
                          <IconButton
                              style={{width: '35px', height: '35px', marginLeft: '10px'}}>
                            {user?.result?.isEmailVerified? (
                                <img
                                    src={PATH + "/assets/img/wallet/verified_icon/email_verified.png"}
                                    style={{width: '35px', height: '35px'}}
                                />
                            ) : (
                                <img
                                    src={PATH + "/assets/img/wallet/verified_icon/email_pre_verified.png"}
                                    style={{width: '35px', height: '35px'}}
                                    onClick={handleEmailOpen}
                                />
                            )}
                            <Dialog open={emailOpen} onClose={handleEmailOpen} BackdropProps={{invisible: true}}>
                              <DialogTitle sx={{padding: 0}}>
                                <DialogActions>
                                  <Button onClick={handleEmailClose}>X</Button>
                                </DialogActions>
                                <Typography
                                    sx={{
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      fontSize: '20px',
                                      color: 'white',
                                    }}
                                >
                                  Verify Email</Typography>
                              </DialogTitle>
                              <DialogContent>
                                <Typography
                                    sx={{
                                      textAlign: 'center',
                                      fontSize: '12px',
                                      color: 'white'
                                    }}
                                >
                                  A 6-digit PIN number has been sent to your email.</Typography>

                                <Typography
                                    sx={{
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      fontSize: '16px',
                                      color: 'white',
                                      paddingTop: '5px'
                                    }}
                                >
                                  {user?.result?.email?.substring(0,2)}******@{user?.result?.email?.split("@")[1]}
                                  </Typography>
                                <br/>
                                <Grid container spacing={1} alignItems="center">
                                  {[...Array(6)].map((_, index) => (
                                      <Grid item key={index}>
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            inputRef={(el) => (inputRefs.current[index] = el)}
                                            value={emailCode[index]}
                                            onChange={(e) => handleChangeEmail(index, e.target.value)}
                                            inputProps={{
                                              maxLength: 1,
                                              style: {textAlign: 'center', width: '10px', height: '15px'}
                                            }}
                                        />
                                      </Grid>
                                  ))}
                                </Grid>
                                <br/>
                                <DialogActions sx={{justifyContent: 'center'}}>
                                  <Button variant="contained" color="error" onClick={handleVerifyEmail}>
                                    VERIFY
                                  </Button>
                                </DialogActions>
                                <br/>
                                <Typography
                                    sx={{
                                      textAlign: 'center',
                                      fontSize: '12px',
                                      color: 'white'
                                    }}>
                                  Experiencing any issues?{' '}
                                  <Link href='/contactus' style={{color: 'red', textDecoration: 'none'}}>
                                    Contact Customer Support
                                  </Link>
                                </Typography>
                                <br/>
                                <Typography
                                    sx={{
                                      textAlign: 'center',
                                      fontSize: '12px',
                                      color: 'white'
                                    }}
                                >
                                  Didn't receive the PIN number?{' '}
                                  <Typography
                                      component="span"
                                      sx={{ color: 'green', fontSize: '12px', textDecoration: 'none', cursor: seconds === 0 ? 'pointer' : 'not-allowed' }}
                                      onClick={seconds === 0 ? handleEmailOpen() : null}
                                  >
                                    Resend code ({seconds}s)
                                  </Typography>
                                </Typography>
                              </DialogContent>
                            </Dialog>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box> */}
                  <Divider />
                  <Box my={3}>
                    {screens?.map((item, idx, arr) => (
                      <>
                        {idx === 0 ? (
                          <Box
                            px={3}
                            py={1}
                            sx={{ backgroundColor: theme.palette.primary.main }}
                          >
                            <Typography color="white">
                              {item.category}
                            </Typography>
                          </Box>
                        ) : item?.category !== arr[idx - 1]?.category ? (
                          <Box
                            mt={3}
                            px={3}
                            py={1}
                            sx={{ backgroundColor: theme.palette.primary.main }}
                          >
                            <Typography color="white">
                              {item.category}
                            </Typography>
                          </Box>
                        ) : null}
                        <Box px={3}>
                          <ListItemButton
                            onClick={() => {
                              navigate(Constant.getPath("/profileWallet"), {
                                replace: true,
                                state: { screen: item.code },
                              });
                            }}
                          >
                            <Stack direction="row">
                              {item.icon}
                              <Typography pl={2} pt={1} color="white">
                                {item.label}
                              </Typography>
                            </Stack>
                          </ListItemButton>
                        </Box>
                      </>
                    ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs direction="column" spacing={3}>
                <Grid item xs={1}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="row"
                    pb={3}
                    // sx={{
                    //   bgcolor: theme.palette.background.paper,
                    // }}
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      px={5}
                      alignItems="center"
                      justifyContent="center"
                      width="250px"
                      sx={{
                        bgcolor: theme.palette.background.paper,
                        border: 3,
                        borderRadius: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                    >
                      <Typography variant="body2" color="white">
                        {user?.result?.playerRank}
                      </Typography>
                      <AccountCircleIcon />
                    </Box>
                    {/* <Divider orientation="vertical" flexItem /> */}
                    <Box
                      display="flex"
                      flexDirection="column"
                      px={5}
                      alignItems="center"
                      justifyContent="center"
                      width="250px"
                      sx={{
                        bgcolor: theme.palette.background.paper,
                        border: 3,
                        borderRadius: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                    >
                      <Typography
                        variant="body2"
                        textAlign="center"
                        color="white"
                      >
                        {i18n.t("walletcard.registrationdate")}
                      </Typography>
                      <Typography color="white">
                        {user?.result?.dateRegistered}
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      px={5}
                      alignItems="center"
                      justifyContent="center"
                      width="250px"
                      sx={{
                        bgcolor: theme.palette.background.paper,
                        border: 3,
                        borderRadius: 3,
                        borderColor: theme.palette.primary.main,
                      }}
                    >
                      <Typography
                        variant="body2"
                        textAlign="center"
                        color="white"
                      >
                        {i18n.t("walletcard.amountrequiredforupgrade")}
                      </Typography>
                      <Typography color="white">
                        {user?.result?.nextRankAmountRequired || 0}
                      </Typography>
                      <Typography
                        variant="body2"
                        color={theme.palette.primary.main}
                      >
                        {process.env.REACT_APP_CURRENCY_CODE}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs>
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    flexDirection="column"
                    px={3}
                    py={2}
                    sx={{
                      bgcolor: theme.palette.background.paper,
                      height: "100%",
                    }}
                  >
                    {selectedScreen?.toLowerCase() === "my account" && (
                      <UserProfile
                        user={user?.result}
                        useUpdateProfileMutation={useUpdateProfileMutation}
                        shareLink={shareLink?.result?.url}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "deposit" && (
                      <Deposit
                        depositDetails={depositDetails?.result}
                        useDepositMutation={useDepositMutation}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "withdrawal" && (
                      <Withdrawal
                        withdrawDetails={withdrawDetails?.result}
                        useWithdrawMutation={useWithdrawMutation}
                        user={user?.result}
                        useGetHistoryMutation ={useGetHistoryMutation}
                        useGetBankAccountsQuery={useGetBankAccountsQuery}
                        useGetPromotionRecordMutation={useGetPromotionRecordMutation}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "transaction" && (
                      <Transaction
                        useGetHistoryMutation={useGetHistoryMutation}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "bet record" && (
                      <BetRecord
                        useListBetHistoryMutation={useListBetHistoryMutation}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "promotion record" && (
                      <PromotionRecord
                        useGetPromotionRecordMutation={
                          useGetPromotionRecordMutation
                        }
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "bank account" && (
                        <BankAccount
                            usePlayerBanksMutation={usePlayerBanksMutation}
                        />
                    )}
                    {selectedScreen.toLowerCase() === "change password" && (
                      <ChangePassword
                        useUpdateProfileMutation={useUpdateProfileMutation}
                      />
                    )}
                    {selectedScreen?.toLowerCase() === "id verification" && (
                        <IdVerification
                            useVerifyIdentityMutation={useVerifyIdentityMutation}
                        />
                    )}
                    {selectedScreen?.toLowerCase() === "security center" && (
                        <SecurityCenter
                            usePlayerBanksMutation={usePlayerBanksMutation}
                        />
                    )}
                    {selectedScreen?.toLowerCase() === "invite friends" && (
                        <InviteFriends
                            usePlayerBanksMutation={usePlayerBanksMutation}
                            shareLink={shareLink?.result?.url}
                        />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        )}
        {tabletView && (
          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              alignSelf="center"
              mt={{ xs: 6, sm: 0, md: 0, lg: 0 }}
            >
              {/* {screens?.map((item, index) => (
                  <Grid key={index} item xs={3} sm={2} md={2}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      p={1}
                    >
                      <Button
                        key={index}
                        disableRipple
                        sx={{
                          "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent",
                          },
                          mb: 1,
                        }}
                        onClick={() => {
                          navigate("/profileWallet", {
                            replace: true,
                            state: { screen: item.label },
                          });
                        }}
                      >
                        <Box
                          display="flex"
                          sx={{
                            border: 1,
                            width: "50px",
                            height: "50px",
                          }}
                          justifyContent="center"
                          alignItems="center"
                        >
                          {item.icon}
                        </Box>
                      </Button>
                      <Typography
                        variant="body2"
                        fontSize="12px"
                        textAlign="center"
                      >
                        {item.label}
                      </Typography>
                    </Box>
                  </Grid>
                ))} */}
              <Box>
                <Box sx={{ display: "flex", pb: 1 }}>
                  <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        pr: 2,
                        //pt: 3,
                      }}
                  >
                    <Box
                        component="img"
                        width="20px"
                        height="20px"
                        src={PATH + "/assets/img/wallet/wallet_card/Bell.png"}
                    />
                    <Box display="flex">
                      <Typography fontSize={14} color="white">
                        {t("walletcard.walletbalance")}
                      </Typography>
                      <Typography fontSize={14} color={theme.palette.primary.main}>
                        {` (${process.env.REACT_APP_CURRENCY_CODE})`}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography fontSize={14} color="white">
                        {user?.result?.currentCredit}
                      </Typography>
                      <IconButton
                          onClick={() =>
                              dispatch(apiSlice.util.invalidateTags(["Profile"]))
                          }
                      >
                        <Refresh style={{ color: "#29A829" }} />
                      </IconButton>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Box
                        pl={2}
                        sx={{ display: "flex", alignItems: "flex-end" }}
                        onClick={() => {
                          navigate(Constant.getPath("/profileWallet"), {
                            state: { screen: "Transaction" },
                          });
                        }}
                    >
                      <Typography mr={1} fontSize={14} color="white">
                        {t("walletcard.transactionhistory")}
                      </Typography>
                      <Forward style={{ color: "#29A829" }} />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            {/* <Box>
              <Box
                  sx={{
                    backgroundColor: '#FDB813',
                    padding: '5px',
                    borderRadius: '2px',
                  }}>
                <Typography mr={1} fontSize={12} color="white" style={{fontWeight: 'bold'}}>
                  {t("Verify Your Account NOW")}
                </Typography>
              </Box> */}
              {/*<Box sx={{ display: 'flex', backgroundColor: 'grey', paddingTop: '5px' }}>*/}
              {/*  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>*/}
              {/*    <Typography fontSize={12} sx={{color: 'white',marginLeft:"5px",fontWeight: 'bold' }}>*/}
              {/*      {t("Verify for additional security")}*/}
              {/*    </Typography>*/}
              {/*    <Typography fontSize={12} sx={{color: 'white',marginLeft:"5px",fontWeight: 'bold',marginRight:'5px' }}>*/}
              {/*      {t("25%")}*/}
              {/*    </Typography>*/}
              {/*</Box>*/}
              {/*</Box>*/}
              {/* <Box sx={{display: "flex", backgroundColor: "grey", paddingTop: "5px"}}>
                <Grid container spacing={2} justifyContent="center" marginLeft="5px"> */}
                  {/*<Grid item xs={3} sx={{paddingBottom: '5px',alignItems:'center'}}>*/}
                  {/*  <IconButton*/}
                  {/*      style={{width: '35px', height: '35px', backgroundColor: 'lightgrey', marginLeft: '10px'}}>*/}
                  {/*    {user?.result?.isIdentityVerified ? (*/}
                  {/*        <img*/}
                  {/*            src={PATH + "/assets/img/wallet/verified_icon/id_verified.png"}*/}
                  {/*            style={{width: '35px', height: '35px'}}*/}
                  {/*        />*/}
                  {/*    ) : user?.result?.isIdVerificationSubmitted ? (*/}
                  {/*        <img*/}
                  {/*            src={PATH + "/assets/img/wallet/verified_icon/id_pre_verified.png"}*/}
                  {/*            style={{ width: '35px', height: '35px' }}*/}
                  {/*            onClick={handleIDVerificationOpen}*/}
                  {/*        />*/}
                  {/*    ) : (*/}
                  {/*    <img src={PATH + "/assets/img/wallet/verified_icon/id_pre_verified.png"} style={{*/}
                  {/*      width: '35px',*/}
                  {/*      height: '35px',*/}
                  {/*    }}*/}
                  {/*         onClick={() => {*/}
                  {/*           navigate(Constant.getPath("/profileWallet"), {*/}
                  {/*             replace: true,*/}
                  {/*             state: { screen: "id verification" },*/}
                  {/*           });*/}
                  {/*         }}*/}
                  {/*    /> )} /!* Adjust width and height as needed *!/*/}
                  {/*  </IconButton>*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={3} sx={{paddingBottom: '5px'}}>*/}
                  {/*  <IconButton*/}
                  {/*      style={{width: '35px', height: '35px', backgroundColor: 'lightgrey', marginLeft: '10px'}}>*/}
                  {/*    {user?.result?.isBankVerified ? (*/}
                  {/*        <img*/}
                  {/*            src={PATH + "/assets/img/wallet/verified_icon/bank_verified.png"}*/}
                  {/*            style={{width: '35px', height: '35px'}}*/}
                  {/*        />*/}
                  {/*    ) : (*/}
                  {/*        <img src={PATH + "/assets/img/wallet/verified_icon/bank_pre_verified.png"} style={{*/}
                  {/*      width: '35px',*/}
                  {/*      height: '35px',*/}
                  {/*    }}*/}
                  {/*      onClick={() => {*/}
                  {/*      navigate(Constant.getPath("/profileWallet"), {*/}
                  {/*      replace: true,*/}
                  {/*      state: { screen: "bank account" },*/}
                  {/*    });*/}
                  {/*    }}/>)} /!* Adjust width and height as needed *!/*/}
                  {/*  </IconButton>*/}
                  {/*</Grid>*/}
                  {/* <Grid item xs={3} sx={{paddingBottom: '5px'}}>
                    <IconButton style={{width: '35px', height: '35px', marginLeft: '10px'}}>
                      {user?.result?.isPhoneVerified ? (
                          <img
                              src={PATH + "/assets/img/wallet/verified_icon/mobile_verified.png"}
                              style={{width: '35px', height: '35px'}}
                          />
                      ) : (
                          <img
                              src={PATH + "/assets/img/wallet/verified_icon/mobile_pre_verified.png"}
                              style={{width: '35px', height: '35px'}}
                              onClick={handlePhoneOpen}
                          />
                      )}

                      <Dialog open={IDVerificationOpen} onClose={handleIDVerificationOpen} BackdropProps={{invisible: true}}>
                        <DialogTitle sx={{padding: 0}}>
                          <DialogActions>
                            <Button onClick={handleIDVerificationClose}>X</Button>
                          </DialogActions>
                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                color: 'white',
                              }}
                          >
                            In-Progress</Typography>
                        </DialogTitle>
                        <DialogContent>
                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '12px',
                                color: 'white'
                              }}
                          >
                            You have submitted your ID Verification. Please wait for approval. </Typography>
                          <br/>
                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '12px',
                                color: 'white'
                              }}>
                            Experiencing any issues?{' '}
                            <Link href='/contactus' style={{color: 'red', textDecoration: 'none'}}>
                              Contact Customer Support
                            </Link>
                          </Typography>
                          <br/>
                        </DialogContent>
                      </Dialog>

                    <Dialog open={phoneOpen} onClose={handlePhoneClose} BackdropProps={{invisible: true}}>
                        <DialogTitle sx={{padding: 0}}>
                          <DialogActions>
                            <Button onClick={handlePhoneClose}>X</Button>
                          </DialogActions>
                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                color: 'white',
                              }}
                          >
                            Verify Mobile Number</Typography>
                        </DialogTitle>
                        <DialogContent>
                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '12px',
                                color: 'white'
                              }}
                          >
                            A 6-digit PIN number has been sent to your phone.</Typography>

                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                color: 'white',
                                paddingTop: '5px'
                              }}
                          >
                            ********{user?.result?.mobileNumber?.substring(user?.result?.mobileNumber?.length - 4, user?.result?.mobileNumber?.length - 1)}</Typography>
                          <br/>
                          <Grid container spacing={1} alignItems="center">
                            {[...Array(6)].map((_, index) => (
                                <Grid item key={index}>
                                  <TextField
                                      variant="outlined"
                                      size="small"
                                      inputRef={(el) => (inputRefs.current[index] = el)}
                                      value={phoneCode[index]}
                                      onChange={(e) => handleChangePhone(index, e.target.value)}
                                      inputProps={{
                                        maxLength: 1,
                                        style: {textAlign: 'center', width: '10px', height: '15px'}
                                      }}
                                  />
                                </Grid>
                            ))}
                          </Grid>
                          <br/>
                          <DialogActions sx={{justifyContent: 'center'}}>
                            <Button variant="contained" color="error" onClick={handleVerifyPhone}>
                              VERIFY
                            </Button>
                          </DialogActions>
                          <br/>
                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '12px',
                                color: 'white'
                              }}>
                            Experiencing any issues?{' '}
                            <Link href='/contactus' style={{color: 'red', textDecoration: 'none'}}>
                              Contact Customer Support
                            </Link>
                          </Typography>
                          <br/>
                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '12px',
                                color: 'white'
                              }}
                          >
                            Didn't receive the PIN number?{' '}
                            <Typography
                                component="span"
                                sx={{ color: 'green', fontSize: '12px', textDecoration: 'none', cursor: seconds === 0 ? 'pointer' : 'not-allowed' }}
                                onClick={seconds === 0 ? handlePhoneOpen : null}
                            >
                              Resend code ({seconds}s)
                            </Typography>
                          </Typography>
                        </DialogContent>
                      </Dialog>
                    </IconButton>
                  </Grid>
                  <Grid item xs={3} sx={{paddingBottom: '5px'}}>
                    <IconButton
                        style={{width: '35px', height: '35px', marginLeft: '10px'}}>
                      {user?.result?.isEmailVerified ? (
                          <img
                              src={PATH + "/assets/img/wallet/verified_icon/email_verified.png"}
                              style={{width: '35px', height: '35px'}}
                          />
                      ) : (
                          <img
                              src={PATH + "/assets/img/wallet/verified_icon/email_pre_verified.png"}
                              style={{width: '35px', height: '35px'}}
                              onClick={handleEmailOpen}
                          />
                      )}
                      <Dialog open={emailOpen} onClose={handleEmailOpen} BackdropProps={{invisible: true}}>
                        <DialogTitle sx={{padding: 0}}>
                          <DialogActions>
                            <Button onClick={handleEmailClose}>X</Button>
                          </DialogActions>
                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: '20px',
                                color: 'white',
                              }}
                          >
                            Verify Email</Typography>
                        </DialogTitle>
                        <DialogContent>
                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '12px',
                                color: 'white'
                              }}
                          >
                            A 6-digit PIN number has been sent to your email.</Typography>

                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                color: 'white',
                                paddingTop: '5px'
                              }}
                          >
                            {user?.result?.email?.substring(0,2)}******@{user?.result?.email?.split("@")[1]}
                          </Typography>
                          <br/>
                          <Grid container spacing={1} alignItems="center">
                            {[...Array(6)].map((_, index) => (
                                <Grid item key={index}>
                                  <TextField
                                      variant="outlined"
                                      size="small"
                                      inputRef={(el) => (inputRefs.current[index] = el)}
                                      value={emailCode[index]}
                                      onChange={(e) => handleChangeEmail(index, e.target.value)}
                                      inputProps={{
                                        maxLength: 1,
                                        style: {textAlign: 'center', width: '10px', height: '15px'}
                                      }}
                                  />
                                </Grid>
                            ))}
                          </Grid>
                          <br/>
                          <DialogActions sx={{justifyContent: 'center'}}>
                            <Button variant="contained" color="error" onClick={handleVerifyEmail}>
                              VERIFY
                            </Button>
                          </DialogActions>
                          <br/>
                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '12px',
                                color: 'white'
                              }}>
                            Experiencing any issues?{' '}
                            <Link href='/contactus' style={{color: 'red', textDecoration: 'none'}}>
                              Contact Customer Support
                            </Link>
                          </Typography>
                          <br/>
                          <Typography
                              sx={{
                                textAlign: 'center',
                                fontSize: '12px',
                                color: 'white'
                              }}
                          >
                            Didn't receive the PIN number?{' '}
                            <Typography
                                component="span"
                                sx={{ color: 'green', fontSize: '12px', textDecoration: 'none', cursor: seconds === 0 ? 'pointer' : 'not-allowed' }}
                                onClick={seconds === 0 ? handleEmailOpen : null}
                            >
                              Resend code ({seconds}s)
                            </Typography>
                          </Typography>
                        </DialogContent>
                      </Dialog>
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </Box> */}
            <Box>
              <WalletCard screens={screens} token="true"/>
            </Box>
            <Box mt={1} mb={mobileView ? 8 : 3}>
            <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="column"
                py={3}
                sx={{
                  bgcolor: theme.palette.background.paper,
                }}
              >
                {selectedScreen?.toLowerCase() === "my account" && (
                  <UserProfile
                    user={user?.result}
                    useUpdateProfileMutation={useUpdateProfileMutation}
                    shareLink={shareLink?.result?.url}
                  />
                )}
                {selectedScreen?.toLowerCase() === "deposit" && (
                  <Deposit
                    depositDetails={depositDetails?.result}
                    useDepositMutation={useDepositMutation}
                  />
                )}
                {selectedScreen?.toLowerCase() === "withdrawal" && (
                  <Withdrawal
                    withdrawDetails={withdrawDetails?.result}
                    useWithdrawMutation={useWithdrawMutation}
                    user={user?.result}
                    useGetHistoryMutation={useGetHistoryMutation}
                    useGetBankAccountsQuery={useGetBankAccountsQuery}
                    useGetPromotionRecordMutation={useGetPromotionRecordMutation}
                  />
                )}
                {selectedScreen?.toLowerCase() === "transaction" && (
                  <Transaction useGetHistoryMutation={useGetHistoryMutation} />
                )}
                {selectedScreen?.toLowerCase() === "bet record" && (
                  <BetRecord
                    useListBetHistoryMutation={useListBetHistoryMutation}
                  />
                )}
                {selectedScreen?.toLowerCase() === "promotion record" && (
                  <PromotionRecord
                    useGetPromotionRecordMutation={
                      useGetPromotionRecordMutation
                    }
                  />
                )}
                {selectedScreen?.toLowerCase() === "bank account" && (
                    <BankAccount
                        usePlayerBanksMutation={usePlayerBanksMutation}
                    />
                )}
                {selectedScreen?.toLowerCase() === "change password" && (
                  <ChangePassword
                    useUpdateProfileMutation={useUpdateProfileMutation}
                  />
                )}
              {selectedScreen?.toLowerCase() === "id verification" && (
                  <IdVerification
                    useVerifyIdentityMutation={useVerifyIdentityMutation}
                  />
              )}
              {selectedScreen?.toLowerCase() === "security center" && (
                  <SecurityCenter
                      usePlayerBanksMutation={usePlayerBanksMutation}
                  />
              )}
              {selectedScreen?.toLowerCase() === "invite friends" && (
                  <InviteFriends
                      usePlayerBanksMutation={usePlayerBanksMutation}
                      shareLink={shareLink?.result?.url}
                  />
              )}
            </Box>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProfileWallet;
