import { apiSlice } from "./apiSlice";

export const homeApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Banner", "Lookup", "HomePage"],
  endpoints: (builder) => ({
    getBanner: builder.query({
      query: () => "/home/api/v1/banner",
      providesTags: ["Banner"],
    }),
    getLookup: builder.query({
      query: () => "/home/api/v1/lookup",
      providesTags: ["Lookup"],
    }),
    getHomePage: builder.query({
      query: () => "/home/api/v1/homePage",
      providesTags: ["HomePage"],
    }),
  }),
});

export const { useGetBannerQuery, useGetLookupQuery, useGetHomePageQuery } =
  homeApiSlice;
