import { apiSlice } from "./apiSlice";

export const bonusApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["BonusDetails", "BonusList", "ClaimBonus", "PromotionRecord"],
  endpoints: (builder) => ({
    getBonusDetails: builder.query({
      query: (bonusCode) => `/bonus/api/v1/bonusDetails/${bonusCode}`,
      providesTags: ["BonusDetails"],
    }),
    getBonusList: builder.query({
      query: (category) => `/bonus/api/v1/bonusList?category=${category}`,
      providesTags: ["BonusList"],
    }),
    claimBonus: builder.mutation({
      query: (bonusCode) => ({
        url: "/bonus/api/v1/claimBonus",
        method: "POST",
        body: bonusCode,
      }),
      providesTags: ["ClaimBonus"],
      invalidatesTags: ["BonusList", "Profile"],
    }),
    getPromotionRecord: builder.mutation({
      query: (queryBody) => ({
        url: "/bonus/api/v1/history",
        method: "POST",
        body: queryBody,
      }),
      providesTags: ["PromotionRecord"],
    }),
  }),
});

export const {
  useLazyGetBonusDetailsQuery,
  useLazyGetBonusListQuery,
  useClaimBonusMutation,
  useGetPromotionRecordMutation,
} = bonusApiSlice;
